@import "../../../assets/styles/basics";

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: $color-neutral;
  padding: 1rem;
  @include regular-text-font;
}

.title {
  font-weight: 600;
  padding: 1rem 0 0.875rem 0;
}

.text {
  padding: 0 0 1.75rem 0;
  color: $color-font-secondary;
  line-height: 1.5rem;
}

.card {
  background: $color-primary;
  color: $color-neutral;
  border-radius: 0.75rem;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 1.25rem 0.5rem 1.25rem;
  text-align: end;
}

.user {
  @include regular-title-font(400);
  padding-bottom: 0.5rem;
}

.wcpid {
  @include regular-text-font-smaller;
}

.banner {
  position: relative;
}

.qr-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 38vw;
  height: 38vw;
  background-color: $color-neutral;
  border-radius: 0.5rem;
  overflow: hidden;
  padding: 0.125rem;
}

.qr-code {
  width: 100%;
  height: 100%;
}

.footer {
  text-align: center;
  padding: 0.75rem;
  @include regular-text-font;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;
}
