@import "../../assets/styles/basics";

.container {
  padding: 0 1.25rem 1.25rem 1.25rem;
  background: $color-neutral;
}
.title {
  margin: 1.25rem 0 0.5rem 0;
  font-size: 0.9rem;
}

.full-height {
  height: 100%;
}

.no-padding {
  padding: 0;
}