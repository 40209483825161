@import "../../assets/styles/basics";

.container {
  @include flex-gap(10px);
}

.title-container {
  display: flex;
  justify-content: space-between;
}

.heading {
  margin: 0;
  @include regular-text-font-smaller(500);
  color: $color-font-secondary;
}

.link {
  @include regular-text-font-smaller(500);
  color: $color-link;
}