@import "../../assets/styles/basics";

.container {
  display: grid;
  grid-template-columns: 0.25rem 2.5rem auto;
  column-gap: 1rem;
  align-items: center;
}

.date-info {
  text-align: center;
  justify-content: center;
  @include flex-gap(5px);
}

.day-in-month {
  @include regular-title-font(600);
}

.day-of-week {
  @include regular-text-font-smaller;
}

.collection-info {
  border-radius: 5px;
  padding: 0.5rem 0.25rem;
  @include flex-gap(5px);
}

.collection-label {
  @include regular-text-font(500);
}

.collection-date {
  @include regular-text-font-smaller;
  color: $color-font-secondary;
}