@import "../../../assets/styles/basics";

.change-password-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $color-neutral;
}

.button-container {
  height: 4.4rem;
  padding: 0.625rem 1.25rem;
}
