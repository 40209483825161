@import "../../assets/styles/basics";

.container {
  display: grid;
  row-gap: 8px;
  margin-left: 0.35rem;
}

.title {
  font-weight: 600;
  font-size: 1.5rem;
  grid-area: 1 / 1 / 2 / 2;
  margin: 0;
}

.username {
  grid-area: 2 / 1 / 3 / 2;
  font-size: 1.125rem;
  color: $color-font-secondary;
}

.notifications {
  grid-area: 1 / 2 / 3 / 3;
  align-self: start;
  justify-self: end;
}
