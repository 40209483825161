@import "../../../assets/styles/basics";

.change-email-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.button-container {
    background: $color-neutral;
  }