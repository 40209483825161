@import "../../assets/styles/basics";

.center {
  @include flex-gap(1.5rem);
  padding: 0 10%;
  margin-top: 70%;

  & > h1 {
    @include regular-title-font;
    text-align: center;
  }

  & > p {
    color: $color-font-secondary;
    text-align: center;
    line-height: 1.5rem;
  }
}
