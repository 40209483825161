.container {
  display: flex;
  flex-direction: column;

  justify-content: space-around;
  align-items: center;
  flex: 1;
  align-self: center;
  width: 100%;
}

.doughnutChart {
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
  flex: 1;
}

.labels {
  margin-top: 0.5rem;
  overflow-y: auto;
  height: 200px;
  flex-wrap: wrap;
  width: 100%;
  display: flex;
  justify-content: center;
}

.label {
  border-radius: 15px;
  margin: 5px 5px;
  letter-spacing: -0.28px;
  color: #ffffff;
  padding: 7px 12px 4px 12px;
  display: inline-flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  align-self: center;
}
