@import "../../../assets/styles/basics";

.item {
  display: flex;
  flex-direction: column;
  color: $color-font-secondary;
  padding: 0.5rem;
  gap: 0.25rem;
  @include regular-text-font-smaller;
}

.itemTitle {
  @include regular-text-font(500);
  color: $color-font-primary;
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.divider {
  height: 0.25rem;
  width: 0.25rem;
  border-radius: 50%;
  background-color: $color-font-primary;
}

.wasteTypes {
  line-height: 1.25;
}
