@import "../../../assets/styles/basics";

.login-container {
  @include flex-gap(2rem);
  padding: 1.25rem;
  border-radius: 20px;
}

.inputs-container {
  @include flex-gap(0.75rem);
}

.cant-login {
  display: flex;
  justify-content: center;
  font-size: 0.75rem;
}

.cant-login-link {
  color: $link-color-secondary;
  text-decoration: underline;
}
