$color-primary: #3eaf10;
$color-primary-hover: #19b932;
$color-primary-transparent: rgba(62, 175, 16, 0.5);
$color-font-secondary: #5b7289;
$color-font-primary: #25273a;
$color-link: #0682fe;
$color-info: #88a6d1;
$color-background-cover: #eff2f7;

$color-font-title: #25273a;

$color-button-primary: #46af1a;

$color-neutral: #ffffff;

$color-divider-line: #ebecee;

$color-selected-option: #ebebeb;

$link-color: #afc4e2;

$link-color-secondary: #88a6d1;

$banner-label-color: #b0bbcb;

$border-radius: 8px;
$border-color: #cfd6e0;

$color-secondary: #afc4e219;

$color-best-primary: #64d435;
$color-normal-primary: #fcac0b;
$color-worst-primary: #fb1717;

$color-font-positive: #21d935;
$color-font-negative: #ff5a55;

$font-primary: "Sora";

@mixin flex-gap($gap: 1rem) {
  display: flex;
  flex-direction: column;
  gap: $gap;
}

@mixin regular-text-font($weight: 400) {
  font-family: $font-primary;
  font-weight: $weight;
  font-size: 0.875rem;
}

@mixin regular-text-font-smaller($weight: 400) {
  font-family: $font-primary;
  font-weight: $weight;
  font-size: 0.75rem;
}

@mixin heavy-text-font {
  font-family: $font-primary;
  font-weight: 600;
  font-size: 1.375rem;
}

@mixin regular-title-font($weight: 500) {
  font-family: $font-primary;
  font-weight: $weight;
  font-size: 1.25rem;
}

@mixin full-divider-line {
  border-bottom: 1.5px solid $color-divider-line;
}

/* ------------------------------------------------------------------- 
        Assign the default/constant/env values to CSS variables
    */

:root {
  --safe-area-inset-top: 0px;

  @supports (top: constant(safe-area-inset-top)) {
    --safe-area-inset-top: constant(safe-area-inset-top);
    // --safe-area-inset-right: constant(safe-area-inset-right);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    // --safe-area-inset-left: constant(safe-area-inset-left);
  }

  @supports (top: env(safe-area-inset-top)) {
    --safe-area-inset-top: env(safe-area-inset-top);
    // --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    // --safe-area-inset-left: env(safe-area-inset-left);
  }
}

.negative-text {
  color: $color-font-negative;
}

.positive-text {
  color: $color-font-positive;
}

.info-text {
  color: $color-info;
}

.neutral-text {
  color: $color-font-primary;
}

.warning-text {
  color: $color-normal-primary;
}
