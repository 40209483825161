@import "../../assets/styles/basics";

.center {
  height: 100%;
  display: inline-block;
  vertical-align: middle;
  @include flex-gap(1.5rem);
  padding: 50% 10% 10% 10%;

  h1 {
    @include regular-title-font;
    text-align: center;
  }

  p {
    color: $color-font-secondary;
    text-align: center;
    line-height: 1.5rem;
  }
}
