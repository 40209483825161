.container {
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  justify-content: center;
  align-items: center;

  &__spinner {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 5px solid #777;
    border-bottom-color: transparent;
    border-left-color: transparent;
    animation: spin 0.6s cubic-bezier(0.37, 0, 0.63, 1) infinite;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}
