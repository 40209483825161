@import "../../assets/styles/basics";

.button {
  border-radius: 4px;
  border: none;
  padding-block: 0.875rem;
  cursor: pointer;
  width: 100%;
}

.primary {
  background-color: $color-button-primary;
  color: $color-neutral;
  font-size: 1rem;
  font-family: $font-primary;
  font-weight: 600;
}

.max-content {
  width: max-content;
}