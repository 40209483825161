.container {
  display: grid;
  grid-template-columns: 1fr 9fr;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: left;
}
