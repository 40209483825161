@import "../../assets/styles/basics";

.container {
  width: 100%;
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color-font-secondary;
}
