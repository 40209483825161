@import "../../../assets/styles/basics";

.register-container {
  @include flex-gap(1rem);
  padding: 1.25rem;
  border-radius: 20px;
}

.register-successful-container {
  @include flex-gap(1.5rem);
  border-radius: 20px;

  & > h1 {
    @include regular-title-font;
    line-height: 1.8rem;
  }

  & > p {
    @include regular-text-font;
  }
}

.download-container {
  display: grid;
  grid-template-columns: min-content 1fr;
  column-gap: 1rem;

  @media only screen and (max-width: 272px) {
    grid-template: 1fr 1fr / 1fr;
    row-gap: 1rem;
  }
}
