@import "../../assets/styles/basics";

.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > h1 {
    font-weight: 600;
    font-size: 1.25rem;
    margin-top: 1rem;
  }

  & > a {
    text-decoration: underline;
    color: $link-color-secondary;
    @include regular-text-font;
    margin-top: 1rem;
  }
}
