@import "../../assets/styles/basics";

.container {
  display: grid;
  row-gap: 0.5rem;
  margin-left: 0.35rem;
}

.containerNoSecondary {
  display: grid;
  row-gap: 0.5rem;
  grid-template-columns: 50% 50%;
  margin-left: 0.35rem;
}

.main {
  margin-top: 0.5rem;
  grid-area: 1 / 1 / 2 / 2;
  font-size: 1.15rem;
  font-weight: 550;
}

.mainNoSecondary {
  font-size: 1.15rem;
  font-weight: 550;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.secondary {
  font-size: 0.875rem;
  color: $color-font-secondary;
}
.right {
  grid-area: 1/2/3/3;
  align-self: center;
  justify-self: flex-end;
}

.rightNoSecondary {
  font-size: 0.875rem;
  color: $color-font-secondary;
  justify-self: flex-end;
}
