@import "../../assets/styles/basics";

.item {
  display: grid;
  @include regular-text-font;
  grid-template-columns: 1.2fr 10fr 3fr 7.8fr;
  height: 2.35rem;
}

.bar {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.type {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.percantage {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: end;
}

.weight {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: end;
  font-weight: 600;
}
