@import "../../../assets/styles/basics";

.howToRegisterContainer {
  @include flex-gap(1rem);
  padding: 1.25rem;
  border-radius: 20px;

  & > p {
    @include regular-text-font;
    line-height: 1.6rem;
  }
}
