@import "../../assets/styles/basics";

.container {
  &.no-border * {
    border: none;
  }

  :global(.Mui-focused) {
    :global(.MuiOutlinedInput-notchedOutline) {
      border: 1px solid $border-color !important;
    }
  }

  &.no-border {
    :global(.Mui-focused) {
      :global(.MuiOutlinedInput-notchedOutline) {
        border: none !important;
      }
    }
  }

  :global(.MuiFormControl-root) {
    justify-content: center;
    width: 8.3rem;
    background-color: white !important;
    border-radius: 6px;
  }

  &.full-width {
    :global(.MuiFormControl-root) {
      width: 100%;
    }
  }

  &.smaller-height {
    :global(.MuiFormControl-root) {
      height: 2.75rem;
    }
  }

  :global(.MuiSelect-select) {
    font-size: 0.9rem;
    font-family: "Sora";
  }
}
