@import "../../assets/styles/basics";

.navigation {
  background-color: $color-neutral;
  width: 100vw;
  height: 3.4rem;
}

.list {
  margin: 0;
  padding: 0.5em;
  list-style-type: none;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
