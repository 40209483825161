@import "../../assets/styles/basics";

.container {
  display: grid;
  row-gap: 4px;
  grid-template-columns: 3.5rem auto auto;
}

.main-text {
  grid-area: 1 / 2 / 2 / 3;
  align-self: flex-end;
  @include regular-text-font(500);
}

.secondary-text-container {
  grid-area: 2/ 2/ 3/ 3;
  @include regular-text-font-smaller;
  align-self: flex-start;
  color: $color-font-secondary;
  display: flex;
  align-items: center;
  gap: 4px;
}

.divider {
  height: 4px;
  width: 4px;
  border-radius: 50%;
  background-color: $color-font-secondary;
}

.right-text {
  grid-area: 1/ 3/ 3/ 4;
  align-self: center;
  justify-self: end;
  @include heavy-text-font;

  &.no-secondary {
    font-size: 0.875rem;
    align-self: center;
  }
}

.icon {
  grid-area: 1 / 1 / 3 / 2;
  align-self: center;
  justify-self: start;
}
