@import "../../assets/styles/basics";

.container {
  display: grid;
  grid-template-rows: 4fr 5fr 1.25fr;

  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
}

.inner {
  padding: 1.5rem 1rem 1rem 1rem;
  @media (min-width: 768px) {
    width: 768px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
}

.icon-container {
  display: grid;
  place-items: center;

  transform: translateY(20vh);

  padding: 1rem 0;

  animation: slide-icon 1.1s ease;
  animation-delay: 0.8s;
  animation-fill-mode: forwards;
}

@keyframes slide {
  from {
    transform: translateY(100vh);
    opacity: 0;
  }

  to {
    transform: translateY(0vh);
    opacity: 1;
  }
}

@keyframes slide-icon {
  from {
    transform: translateY(20vh);
  }

  to {
    transform: translateY(0vh);
  }
}

.slider {
  transform: translateY(100vh);

  animation: slide 1.3s ease;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
}

.koala-logo {
  text-align: center;
  width: 100%;
  color: white;
  flex-direction: column;
  display: flex;
  align-items: center;
  z-index: 10;
  line-height: 1.75rem;
  @include regular-text-font-smaller;
}
