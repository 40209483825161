@import "../../assets/styles/basics";

.container {
  width: 0.4rem;
  height: 0;
  border-radius: $border-radius;
  animation: grow 0.75s forwards;
}

@keyframes grow {
  0% {
    height: 0;
  }
  100% {
    height: 2.5rem;
  }
}
