@import "../../../assets/styles/basics";

.container {
  @include flex-gap(2rem);
  padding: 1.5rem 1rem 1rem 1rem;
}

.graphContainer {
  border-radius: 8px;
  background: $color-neutral;
  padding: 1.175rem 0 2rem 0;
}

.title {
  display: grid;
  padding: 0 1rem 2rem 1rem;
  justify-content: space-between;
  grid-template: 2fr 1fr / 4fr 1fr;
  row-gap: 0.25rem;

  & > h3 {
    @include regular-text-font(600);
    grid-area: 1 / 1 / span 1 / span 1;
    align-self: flex-end;
  }
  & > h2 {
    grid-area: 1 / 2 / span 2 / span 1;
    // margin-top: 0.77rem;
    @include heavy-text-font;
    line-height: 1.1rem;
    justify-self: flex-end;
    align-self: center;
  }
  & > span {
    @include regular-text-font-smaller;
    color: $color-font-secondary;
    grid-area: 2 / 1 / span 1 / span 2;
  }
}
