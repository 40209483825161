@import "../../../assets/styles/basics";

.item {
  display: grid;
  row-gap: 10px;
  grid-template-columns: 1fr 1fr;
  padding: 0.75rem;
}

.date {
  @include regular-text-font;
}

.weight {
  @include regular-text-font(600);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-end;
}
