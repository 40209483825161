.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  align-self: center;
}

.lineChart {
  flex: 1;
  width: 21rem;
  height: 17.5rem;
}

.labels {
  display: flex;
  width: 90%;
  justify-content: center;
  margin-top: 0.5rem;
}

.label {
  border-radius: 15px;
  margin: 0 5px;
  letter-spacing: -0.28px;
  color: #ffffff;
  padding: 7px 12px 4px 12px;
}

.lineChartContainer {
  padding: 0 0 0.5rem 0.5rem;
  width: 100%;

  @media (min-width: 800px) {
    padding: 0 1rem;
  }
}
