@import "../../assets/styles/basics";

.box {
  padding: 1rem;
}

.rounded {
  border-radius: $border-radius;
}

.primary {
  background-color: $color-neutral;
}
