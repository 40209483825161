@import "../../assets/styles/basics";

.container {
  border-radius: 10px;
  padding: 1rem;
  color: white;
  &.error {
    background-color: $color-font-negative;
  }
  &.success {
    background-color: $color-primary-hover;
  }
}
