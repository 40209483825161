@import "../../assets/styles/basics";

.container {
  display: grid;
  row-gap: 3px;
  grid-template-columns: 3.5rem auto auto;
}

.main-text {
  grid-area: 1 / 2 / 2 / 3;
  align-self: flex-end;
  @include regular-text-font(500);

  &.no-secondary {
    grid-row-end: 3;
    align-self: center;
    font-weight: 400;
  }
}

.secondary-text {
  grid-area: 2/ 2/ 3/ 3;
  @include regular-text-font-smaller;
  align-self: flex-start;
  color: #5b7289;
}

.right-text {
  grid-area: 1/ 3/ 3/ 4;
  align-self: center;
  justify-self: end;
  @include heavy-text-font;

  &.no-secondary {
    font-size: 0.875rem;
    align-self: center;
  }
}

.icon {
  grid-area: 1 / 1 / 3 / 2;
  align-self: center;
  justify-self: start;
}
