@import "../../../assets/styles/basics";

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.contentWithPadding {
  padding: 1rem;
}

.languageItem {
  padding: 1rem;
  @include regular-text-font;
  height: 3rem;
}

.selectedItem {
  color: $color-button-primary;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.buttonContainer {
  padding: 0.7rem 1rem;
  background-color: $color-neutral;
  @include full-divider-line;
}
