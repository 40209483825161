@import "../../assets/styles/basics";

.container {
    padding: 1.25rem;
}

.info-box {
    background-color: $color-background-cover;
    border-radius: $border-radius;
    display: grid;
    grid-template: 1fr 10fr 1fr / auto auto;
    height: 3.5rem;
    padding: 0.5rem;
    color: $color-font-secondary;
    @include regular-text-font;
    column-gap: 0.5rem;
}

.info-icon {
    grid-area: 1 / 1 / span 1 / span 1;
}

.cancel-icon {
    grid-area: 1 / 3 / span 1 / span 1;
}

.text {
    grid-area: 1 / 2 / span 2 / span 1;
    align-self: center;
    line-height: 1rem;
}