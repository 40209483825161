@import "../../assets/styles/basics";

.container {
  padding: 1.6rem 1.75rem 1rem 1.75rem;
  display: grid;
  justify-content: center;

  width: 100%;
  align-items: center;

  grid-template-columns: 1fr auto 1fr;

  &.neutral {
    background: $color-neutral;
  }

  &.inherit {
    background: inherit;
  }

  &.includeDivider {
    @include full-divider-line;
  }
}

.title {
  font-weight: 600;
  font-size: 1.1rem;
  line-height: 1.6rem;
  color: $color-font-title;
  justify-self: center;
}
