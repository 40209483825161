@import "../../assets/styles/basics";

.container {
  width: 100vw;
  /* -------------------------------------------------------------------   
        Calculated height according to modified viewport (normal vh or smaller when iOS safari navigate bar is active)
        See App.tsx and its useEffect
  */
  height: calc(var(--vh, 1vh) * 100);

  display: grid;

  //topBar, content and navBar
  grid-template-rows: auto 1fr auto;

  @media (min-width: 768px) {
    width: 768px;
  }

  &.no-fixed-top {
    //content including topBar, navBar
    grid-template-rows: 1fr auto;
  }

  &.welcome-bg-image {
    background: transparent center/cover no-repeat padding-box;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4)),
      image-set(
        "../../../public/img/meadow.png" 1x,
        "../../../public/img/meadow@2x.png" 2x,
        "../../../public/img/meadow@3x.png" 3x
      );

    @media only screen and (min-width: 768px) {
      background-image: image-set(
        "../../../public/img/meadowDesktop.png" 1x,
        "../../../public/img/meadowDesktop@2x.png" 2x,
        "../../../public/img/meadowDesktop@2x.png" 3x
      );
    }
  }
}

.main-content {
  overflow: auto;
}

.inner-container {
  @include flex-gap(1.5rem);
  padding: 0 4% 4% 4%;
  background: $color-background-cover;

  &.fixed-top {
    padding: 0 4% 4% 4%;
  }
}

@supports (padding: Max(0px)) {
  .inner-container {
    /* -------------------------------------------------------------------   
         Use the CSS variables in the max function   
    */
    padding-top: Max(6.25%, var(--safe-area-inset-top));
  }
}

.container-without-padding {
  @include flex-gap(0);
  height: 100%;
}

.buttonContainer {
  height: 6.25rem;
  background: $color-neutral;
  margin: auto 0 0 0;
  padding: 1rem 1.25rem 2.2rem 1.25rem;
}

.top-bar {
  padding: 4% 4% 3% 4%;
}
